import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormErrorsComponent } from '../form-errors/form-errors.component';
import { Grow } from '../../animations';
import { IconComponent } from '../../components/icon/icon.component';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-form-input',
    styleUrls: ['./form-input.component.scss'],
    templateUrl: './form-input.component.html',
    standalone: true,
    animations: [Grow],
    imports: [CommonModule, ReactiveFormsModule, FormErrorsComponent, IconComponent],
})
export class FormInputComponent implements OnInit {
    private readonly controlContainer = inject(ControlContainer);

    @Input({ required: true }) controlName!: string;
    @Input() type: 'text' | 'password' = 'text';
    @Input() preview = false;
    @Input() label?: string;
    @Input() scrollToError = true;

    fc!: FormControl<string>;

    readonly preview$ = new BehaviorSubject(false);

    ngOnInit() {
        this.fc = <FormControl<string>>(<FormGroup>this.controlContainer.control).get(this.controlName);
    }

    onPreviewClick() {
        this.preview$.next(!this.preview$.getValue());
    }
}
