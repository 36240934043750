@if (!errors['customs']) {
    @for (error of errors | keyvalue; track error.key) {
        <div class="tw-text-[0.75rem] tw-leading-[0.9375rem] tw-text-danger-600">
            {{ 'forms.errors.message.' + error.key | translate: error.value }}
        </div>
    }
} @else {
    @for (error of errors['customs']; track error.key) {
        <div class="tw-text-[0.75rem] tw-leading-[0.9375rem] tw-text-danger-600">
            {{ error }}
        </div>
    }
}
